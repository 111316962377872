import { Box, Typography } from "@mui/material";
import React from "react";

const FairPracticeCode = () => {
  return (
    <Box
      className="container"
      py={"1rem"}
      sx={{
        "& p,li": {
          fontFamily:
            "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
          textAlign: { sm: "start", xs: "justify" },
        },
      }}
    >
      <div className="block-title text-center">
        <Typography
          component={"h2"}
          sx={{ fontSize: { sm: "2.5rem", xs: "1.5rem" } }}
        >
         Fair Practice Code
        </Typography>
      </div>
      <Typography pb={"1rem"} fontWeight={"600"}>
      PREAMBLE
      </Typography>
      <Typography pb={"1rem"}>
      This Fair Practices Code (hereinafter referred to as “FPC” or “Policy” or “Code”) has been devised by Matsya Fincap Private Limited (hereinafter referred to as “Company” or “we” or “our” or “us”) in accordance with the Guidelines issued by the Reserve Bank of India (“RBI”) on FPC for non-banking financial companies (“NBFC”) through its master circular bearing no. RBI/2015-16/16 DNBR (PD) CC.No.054/03.10.119/2015-16 dated 1st July, 2015(“RBI Circular”) wherein standards for fair business and corporate practices while dealing with their customers are set.
      </Typography>
      <Typography pb={"1rem"}>
      The FPC as mentioned herein below, is in conformity with the Guidelines on Fair Practices Code for NBFC’s as contained in the aforesaid RBI Circular. The guidelines inter alia, cover general principles on adequate disclosures on the terms and conditions of a loan and adopting a non-coercive recovery method. It sets the minimum fair practice standards for the Company to follow when dealing with its customers. It provides information to the customers and explains how the Company is expected to deal with them on a dailybasis.
      </Typography>
      <Typography pb={"1rem"}>
      The Company has displayed the FPC prominently at all offices and customer service centers, as well as on its website for the benefit of all customers, and shall follow it in letter and spirit.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      OBJECTIVE OF THE CODE
      </Typography>
      <Typography pb={"1rem"}>
      The Company’s fair lending practices shall apply across all aspects of its operations including marketing, loan origination, processing, servicing and collection activities. The Company’s commitment to the FPC would be demonstrated in terms of employee accountability, monitoring and auditing programs, training and technology.
      </Typography>
      <Typography pb={"1rem"}>
      The Company’s board of directors and the management are responsible for establishing practices designed to ensure that its operations reflect a strong commitment to fair lending and that all employees are aware of that commitment.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      The Code has been developed with an objective of:
      </Typography>
      <Typography pb={"1rem"}>
      Ensuring fair practices while dealing with customers;
      </Typography>
      <Typography pb={"1rem"}>
      Following transparent, fair, ethical and legally tenable practices while conducting business;
      </Typography>
      <Typography pb={"1rem"}>
      Providing all necessary information and inputs to customers / prospective customers
      </Typography>
      <Typography pb={"1rem"}>
      and promoting a mutually beneficial long-term relationship; and
      </Typography>
      <Typography pb={"1rem"}>
      Building customer confidence in the Company.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      KEY COMMITMENTS
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      The key commitments which the Company promises to follow in its dealings with its customers are:
      </Typography>
      <Typography pb={"1rem"}>
      To provide professional, efficient, courteous, diligent and speedy services;
      </Typography>
      <Typography pb={"1rem"}>
      Not to discriminate on the basis of religion, caste, sex, descent in any manner;
      </Typography>
      <Typography pb={"1rem"}>
      To be fair and honest in any advertisement and marketing of loan products;
      </Typography>
      <Typography pb={"1rem"}>
      To provide customers with accurate and timely disclosure of terms and conditions,
      </Typography>
      <Typography pb={"1rem"}>
      costs, rights and liabilities as regards loan transactions;
      </Typography>
      <Typography pb={"1rem"}>
      To provide assistance or advise to customers seeking loans;
      </Typography>
      <Typography pb={"1rem"}>
      To attempt in good faith to resolve any disputes or differences with customers by
      </Typography>
      <Typography pb={"1rem"}>
      setting up complaint redressal system within the Company;
      </Typography>
      <Typography pb={"1rem"}>
      To comply with all the regulatory requirements in good faith; and
      </Typography>
      <Typography pb={"1rem"}>
      To implement compensation and other policies that align the interests of owners and management.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      APPLICATIONS FOR LOANS AND THEIR PROCESSING
      </Typography>
      <Typography pb={"1rem"}>
      The loan application forms of the Company will include necessary information which are likely to affect the interests of the prospective borrower as well as details of all documents that are required to be submitted by the borrower.
      </Typography>
      <Typography pb={"1rem"}>
      The Company will provide the necessary information to facilitate the borrower in making a meaningful comparison with similar terms and conditions offered by other NBFC’s and taking an informed decision.
      </Typography>
      <Typography pb={"1rem"}>
      The Company has a mechanism of giving an acknowledgement for receipt of the loan application forms to its borrowers and will inform the borrower about its decision within reasonable period of time from the date of receipt of all the required information in full.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      LOAN APPRAISAL AND TERMS/CONDITIONS
      </Typography>
      <Typography pb={"1rem"}>
      The Company shall conduct a due diligence on the credit worthiness of the borrower, which will be an important parameter for taking decision on the application. The assessment would be in line with the Company’s credit policies, norms and procedures in respect thereof.
      </Typography>
      <Typography pb={"1rem"}>
      The Company shall scrutinize the information submitted by the borrower and additional data, if any, required should be called promptly to facilitate expeditious disposal of the loan application.
      </Typography>
      <Typography pb={"1rem"}>
      The Company shall convey in writing to the borrower in the vernacular language or a language as understood by the borrower by means of sanction letter or otherwise, the amount of loan sanctioned along with all the terms and conditions including effective rate of interest, any penal charges due to late repayment or any other charges.
      </Typography>
      <Typography pb={"1rem"}>
      The acceptance of the terms and conditions communicated by the borrower shall be preserved by the Company in its records.
      </Typography>
      <Typography pb={"1rem"}>
      The Company shall furnish a copy of the loan agreement in the vernacular language or a language as understood by the borrower along with a copy each of all enclosures quoted in the loan agreement to all the borrowers at the time of sanction / disbursement of loans.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      DISBURSEMENT OF LOANS INCLUDING CHANGES IN TERMS AND CONDITIONS
      </Typography>
      <Typography pb={"1rem"}>
      The Company will give notice to the borrower in the vernacular language or a language as understood by the borrower, of any change in the terms and conditions of the loan, including changes in disbursement schedule, interest rate, service charges, prepayment charges etc. The Company will also ensure that changes in interest rates and charges are affected only prospectively.
      </Typography>
      <Typography pb={"1rem"}>
      Decisions to recall/accelerate payment or performance under the loan agreement will be in consonance with the respective loan agreement.
      </Typography>
      <Typography pb={"1rem"}>
      The Company will release all securities of its borrower only on repayment of all dues by such borrower, or only on realization of the outstanding amount of the borrower’s availed limit, subject to any legitimate right or lien for any other claim which the Company may have against the borrower. If such right of set off is to be exercised, the borrower will be given notice about the same with full particulars about the remaining claims and conditions under which the Company will be entitled to retain the securities till the relevant claim is settled or paid by the borrower. Due No Objection Certificate (“NOC”) will be issued to the borrower on fulfilment of said terms within fifteen (15) workings days of completion of formalities.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      GENERAL
      </Typography>
      <Typography pb={"1rem"}>
      The Company will refrain from interference in the affairs of its borrowers except for the purposes provided in the terms and conditions of the respective loan agreements (unless information, not earlier disclosed by the borrower, has been noticed).
      </Typography>
      <Typography pb={"1rem"}>
      Our field staffs shall not call on the borrowers before 6 am and after 8 pm and shall not visit their residence or work place on days of festival, marriage etc., or any mourning occasions for the purpose of recovery.
      </Typography>
      <Typography pb={"1rem"}>
      The details of loan product, the terms and conditions of the loan terms, interest payments, etc. shall be advised in detail in local language and necessary consent is obtained from the applicant.
      </Typography>
      <Typography pb={"1rem"}>
      We shall ensure that the procedure for application of loan is not cumbersome and loan disbursements are made as per pre-determined time schedule driven through the well-defined process.
      </Typography>
      <Typography pb={"1rem"}>
      In case of receipt of request from the borrower for transfer of borrowal account, the consent or otherwise i.e., objection of the Company, if any, will be conveyed to the borrower within twenty-one (21) days from the date of receipt of the borrower’s request. Such transfer will be as per transparent contractual terms in consonance with applicablelaw.
      </Typography>
      <Typography pb={"1rem"}>
      In the matter of recovery of outstanding dues of its borrower, the Company shall not resort to undue harassment viz. persistently bothering the borrowers at odd hours, use of muscle power for recovery of loans/dues, etc. Training will be imparted to ensure that Company’s staff is adequately trained to deal with customers in an appropriate manner.
      </Typography>
      <Typography pb={"1rem"}>
      In accordance with the circular no. DNBS(PD). CC. No. 399/03.10.42 /2014-15 dated July 14, 2014 issued by RBI to ensure customer protection and to bring in uniformity about prepayment of various loans by borrowers of banks and NBFCs, the Company shall not charge foreclosure charges/ pre-payment penalties on all floating rate term loans sanctioned to individual borrowers.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      GRIEVANCE REDRESSAL MECHANISM
      </Typography>
      <Typography pb={"1rem"}>
      The customer service and satisfaction are the prime focus of the Company. The Company has come up with a lot of initiatives that are oriented towards providing a better customer experience and an efficient complaints redressal mechanism with a view to providing enhanced experience to our customers.
      </Typography>
      <Typography pb={"1rem"}>
      In order to make the Company redressal mechanism more meaningful and effective, the board of directors of the Company has laid down a Grievance Redressal Mechanism (“GRM”) in compliance with various regulations applicable on the Company. The board of directors will periodically review the compliance of the FPC and the effectiveness of GRM.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      Machinery to handle customer complaints/ grievances
      </Typography>
      <Typography pb={"1rem"}>
      Customers who wish to provide feedback or send in their complaint may contact the customer service team using the following channels between 10:00 am and 6:00 pm, from Monday to Saturday (except on national holidays).
      </Typography>
      {/* <Typography pb={"1rem"}>
      Email at: support@creditlab.in
      </Typography> */}
      <Typography pb={"1rem"}>
      In case the complaint is not resolved within the given time or if the customer is not satisfied with the resolution provided by the customer service team, the customer can approach the Grievance Redressal Officer at the below mentioned mail.
      </Typography>
      {/* <Typography pb={"1rem"}>
      Email Id: grievance@creditlab.in
      </Typography> */}
      <Typography pb={"1rem"} fontWeight={"600"}>
      Mandatory display requirements
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      The Company shall ensure the following at all its offices and customer service centers:
      </Typography>
      <Typography pb={"1rem"}>
      Display GRM.
      </Typography>
      <Typography pb={"1rem"}>
      Appropriate arrangement for receiving complaints and suggestions.
      </Typography>
      <Typography pb={"1rem"}>
      Display of the name and contact details of the Grievance Redressal Officer.
      </Typography>
      <Typography pb={"1rem"}>
      Display of the name, address and telephone no. of the Officer-in-Charge of the Regional Office of DNBS of RBI under whose jurisdiction the registered office of the Company falls
      </Typography>
      <Typography pb={"1rem"}>
      Time frame
      </Typography>
      <Typography pb={"1rem"}>
      To register complaints, the customers may use any of the channels mentioned above.
      </Typography>
      <Typography pb={"1rem"}>
      Complaints that are received at our end will be seen in the right perspective and would be analyzed from all possible angles.
      </Typography>
      <Typography pb={"1rem"}>
      The communication of the Company stand on any issue will be provided to the customers. Complaints that require some time for examination of issues involved will be acknowledged promptly.
      </Typography>
      <Typography pb={"1rem"}>
      The Company shall endeavor to dispose off the complaint/dispute within a period of one (1) month from date of receipt of complaint.
      </Typography>
      <Typography pb={"1rem"}>
      The aforesaid Policy will be reviewed periodically /revised as and when there are any new changes incorporated by the Company in handling complaints / grievances of the customer which includes introduction of new grievance channels, if any.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      LANGUAGE AND MODE OF COMMUNICATING FPC
      </Typography>
      <Typography pb={"1rem"}>
      The FPC has been formulated by the Company in English language, duly approved by the board of directors of the Company and is based on the relevant guidelines as outlined by the RBI in this regard.
      </Typography>
      <Typography pb={"1rem"}>
      The Company shall also have its FPC in regional/vernacular languages for customers who are unable to read the same in English.
      </Typography>
      <Typography pb={"1rem"}>
      The FPC has been published by the Company on its website for information of all concerned stake holders.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      REGULATION OF EXCESSIVE INTEREST CHARGED
      </Typography>
      <Typography pb={"1rem"}>
      The Company has laid down appropriate internal principles and procedures in determining interest rates and processing and other charges.
      </Typography>
      <Typography pb={"1rem"}>
      The board of directors of the Company has adopted an interest rate model taking into account relevant factors such as cost of funds, margin and risk premium for determining rate of interest to be charged for loans and advances.
      </Typography>
      <Typography pb={"1rem"}>
      The rate of interest shall be disclosed to the borrower or customer in the application form and communicated explicitly in the sanction letter and is also made available on the website of the Company.
      </Typography>
      <Typography pb={"1rem"}>
      The rate of interest to be charged depends much upon the risk profile of the borrower. The rate of interest will be annualized so that the borrower is aware of the exact rates that would be charged to the account. The Company will undertake to carry out due diligence on the borrower’s repayment capacity, and comply with RBI’s Know Your Customer (KYC) guidelines.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      FORCE MAJEURE
      </Typography>
      <Typography pb={"1rem"}>
      The various commitments outlined and made by the Company are applicable under the normal operating environment. In the event of force majeure i.e. any event beyond the reasonable control the Company, the Companymay not be able to fulfil the commitments under the FPC to the entire satisfaction of the customer/s and the public in general.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      Grievance Redressal and Customer Relationship Management:
      </Typography>
      <Typography pb={"1rem"}>
      It shall be the endeavour of the Company to improve the quality of service and redress complaints and grievances, if any, of the customers as part of Customer Relationship Management. 
      {/* Customer complaints, shall in the first instance, be addressed to support@creditlab.in. */}
       The designated Customer Satisfaction Executive shall be the first point of contact. Company’s Customer Relationship Management (CRM) Manual specifies the turnaround time (TAT) in respect of redressal of any customer grievances. In the event of non-disposal of complaint by the designated executive, the same shall be escalated using the escalation matrix intimated to customers so that the customer grievance is resolved speedily.
      </Typography>
  

    </Box>
  );
};

export default FairPracticeCode;
