import { Box } from '@mui/material'
import React from 'react'
import Section2 from '../Section2/Section2'
import ServiceSection from './ServiceSection'

const Services = () => {
  return (
    <Box>
    <Section2 />
    <ServiceSection />
    </Box>
  )
}

export default Services