import { Box } from '@mui/material'
import React from 'react'
import AboutSection from './AboutSection'

const About = () => {
  return (
<Box>
     <AboutSection />

</Box>
  )
}

export default About