import { Box, Button, Typography } from "@mui/material";
import React from "react";
import new_banner from "../../assets/images/banner7.jpg";

const Banner = ({ handleModalOpen }) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${new_banner})`,
        height: "980px",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        "@media (max-width:900px)": {
          backgroundSize: "cover",
          backgroundPosition: "50%",
          backgroundRepeat: "no-repeat",
          height: "470px",
        },
      }}
    >
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            left: "10%",
            top: "200px",
            position: "absolute",
            "@media(max-width:900px)": {
              width: "100%",
              top: "50px",
              left: "2%",
            },
          }}
        >
          <Box
            sx={{
              color: "#ffffff99",
              fontSize: "2.3rem",
              "@media (max-width:900px)": {
                width: "100%",
                fontSize: "1.25rem",
              },
            }}
          >
            Simple & Secure Payment Process
          </Box>
          <Box sx={{ color: "white", fontSize: "4rem" }}>
            <Typography
              component={"p"}
              sx={{
                color: "white",
                fontSize: "5rem",
                lineHeight: 1.3,
                "@media (max-width:900px)": { width: "100%", fontSize: "2rem" },
              }}
            >
              Connecting
            </Typography>
            <Typography
              component={"p"}
              sx={{
                color: "white",
                fontSize: "5rem",
                lineHeight: 1.3,
                "@media (max-width:900px)": { width: "100%", fontSize: "2rem" },
              }}
            >
              All Your Loan{" "}
            </Typography>
            <Typography
              component={"p"}
              sx={{
                color: "white",
                fontSize: "5rem",
                lineHeight: 1.3,
                "@media (max-width:900px)": { width: "100%", fontSize: "2rem" },
              }}
            >
              Needs
            </Typography>
          </Box>
          <Box
            sx={{
              padding: "1rem 0rem",
              "@media (max-width:900px)": { width: "50%" },
            }}
          >
            <Button
              sx={{
                background: `linear-gradient(to right, hsl(210 84% 62% / 46), #6f59d2)`,
                color: "#fff",
                padding: "1rem",
                "@media (max-width:900px)": { padding: "0.8rem 0.7rem" },
                fontWeight: 600,
                fontSize: "1rem",
                borderRadius: "5px",
              }}
              onClick={handleModalOpen}
            >
              Apply For Loan
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Banner;
