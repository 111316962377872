import { Box, Typography } from "@mui/material";
import React from "react";

const GrievanceRedressal = () => {
  return (
    <Box
      className="container"
      py={"1rem"}
      sx={{
        "& p,li": {
          fontFamily:
            "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
          textAlign: { sm: "start", xs: "justify" },
        },
      }}
    >
      <div className="block-title text-center">
        <Typography
          component={"h2"}
          sx={{ fontSize: { sm: "2.5rem", xs: "1.5rem" } }}
        >
         Grievance Redressal policy
        </Typography>
      </div>
      <Typography pb={"1rem"}>
      Matsya Fincap Private Limited (hereinafter referred to as “Matsya fincap” or “Company” or “We” or “us”) is a Non-Banking Financial Company registered with the Reserve Bank of India (“RBI”) with its registered office address at KHASRA NO. 1141, VILLAGE BHADKOL ALWAR Alwar RJ 301001 IN and is in the business of providing of short term unsecured loans. Matsya Fincap is committed towards its customers and has put together the undermentioned Grievance Redressal Policy (hereinafter referred to as “Policy”) for an effective resolution of all grievances/complaints received by Matsya Fincap from its customers.
      </Typography>
      <Typography pb={"1rem"}>
      Matsya Fincap realizes that quick and effective handling of complaints as well as prompt corrective and preventive actions to improve processes are essential to provide excellent customer service to all segments of customers.
      </Typography>
      <Typography pb={"1rem"}>
      We aim to resolve any complaint or grievance which may arise as a result of any gap in the promised and delivered service levels. This shall also apply to all queries and/or feedbacks received from the customers of Matsya fincap. We encourage our customers to provide feedback and raise grievances in respect of our services and we aim to improve the quality of our products and services.
      </Typography>
      <Typography pb={"1rem"}>
      We have put in place a very robust and customer friendly structure to ensure that all grievances are resolved in a timely and effective manner and customers are advised to make use of our tiered redressal mechanism.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      1 BASIS OF THE POLICY
      </Typography>
      <Typography pb={"1rem"}>
      1.1 The Policy has been drafted as per directions issued by RBI on Fair Practices Code for Non-Banking Financial Companies.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      2 OBJECTIVE
      </Typography>
      <Typography pb={"1rem"}>
      2.1 The objective of this Policy is to ensure fair and equal treatment to all its customers without any bias -irrespective of caste, creed, race, gender, special abilities, on all occasions and to minimize the recurrence of similar issues in the future by adopting a structured grievance redressal framework which is supported by a review mechanism.
      </Typography>
      <Typography pb={"1rem"}>
      2.2 Complaints raised by customers are dealt with courtesy and in a timely manner.
      </Typography>
      <Typography pb={"1rem"}>
      2.3 The customers are made aware of avenues to escalate their complaints within the organization and their rights to opt for alternative remedies if they are not fully satisfied with the response or resolution to their complaint.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      3 PRINCIPLES OF GRIEVANCE REDRESSAL
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      3.1 The following are the guiding principles at Matsya Fincapfor dealing with customer grievances:
      </Typography>
      <Typography pb={"1rem"}>
      a) Making the process of grievance redressal for customers, efficient and hassle-free.
      </Typography>
      <Typography pb={"1rem"}>
      b) Timely resolution of all grievancesis necessary for business growth. Complaints raised by customers shall be dealt with courtesy and resolved in a timelymanner.
      </Typography>
      <Typography pb={"1rem"}>
      c) Continual evolvement of redressal mechanisms for the resolution of customer grievances.
      </Typography>
      <Typography pb={"1rem"}>
      d) Matsya Fincapbelieves in analysing the root cause of the issue and to resolve it permanently, thus ensuring improved and enhanced customer experience.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      4 REGISTRATION OF COMPLAINTS
      </Typography>
      <Typography pb={"1rem"}>
      4.1 E-mail: Customers can send an email for redressal of issues at the email id(s) mentioned in below section.
      </Typography>
      <Typography pb={"1rem"}>
      4.2 Registered / Corporate / Administrative Offices: Customers can speak to the officials-in-charge at the Company’s offices for resolution of their issues or register their grievances at the Office Complaints Register available at reception.
      </Typography>
      <Typography pb={"1rem"}>
      4.3 Grievance Redressal Officer: Customers can reach out directly to grievance redressal officer over phone and/or email which is provided in the below section.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      5 ESCALATION OF COMPAINTS
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      In order to make the grievance redressal mechanism more meaningful and effective, the board of directors of the Company has laid down a mechanism in compliance with various regulations applicable on the Company. The board of directors will periodically review the effectiveness of grievance redressal mechanism.The Company encourages feedback and thus has created the following tiered escalation system for the customers:
      </Typography>
      <Typography pb={"1rem"}>
      5.1 Level 1- Customers can send their grievances to the Company’s customer service team through email at support@Matsyafincap.com. Customers shall ensure that they quote their Loan ID number in every correspondence with the Company regarding their grievances.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      Response time for resolution of complaints from date of receipt of complaint:
      </Typography>
      <Typography pb={"1rem"} component={"li"}>
      Normal cases (other than the one mentioned below): Fifteen (15) working days.
      </Typography>
      <Typography pb={"1rem"} component={"li"}>
      Fraud cases, legal cases and cases which need retrieval of documents and exceptionally old records: Thirty (30) working days.
      </Typography>
      <Typography pb={"1rem"} component={"li"}>
      If any case needs additional time, Matsya will inform the customer the reasons of delay in resolution within the timelines specified above and provide expected time lines for resolution of the complaint.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      Note: The above time frame can change depending upon the nature and complexity of grievances. Business hours are between 11:00 AM to 8.00 PM IST, Monday through Friday (except public holidays).
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      5.2 Level 2- In case the complaint is not resolved within the given time or if the customer is not satisfied with the resolution provided by the customer service team, the customer can approach the Grievance Redressal Officer. The name and contact details of the Grievance Redressal Officer are as under:
      </Typography>
      <Typography pb={"1rem"}>
      Name: Mr. Anil Kumar Agarwal
      </Typography>
      <Typography pb={"1rem"}>
      Email Id: grievance@Matsyafincap.com
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      6 MANDATORY DISPLAY REQUIREMENTS
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      6.1 The Company shall ensure the following at all its offices, customer service centers and website:
      </Typography>
      <Typography pb={"1rem"}>
      a) Display of grievance redressal mechanism.
      </Typography>
      <Typography pb={"1rem"}>
      b) Appropriate arrangement for receiving complaints andsuggestions.
      </Typography>
      <Typography pb={"1rem"}>
      c) Display of the name and contact details of the Grievance Redressal Officer.
      </Typography>
      <Typography pb={"1rem"}>
      d) Display of the name, address and telephone no. of the Officer-in-Charge of the Regional Office of DNBS of RBI under whose jurisdiction the registered office of the Company falls.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      7 TIME FRAME FOR RESPONSE
      </Typography>
      <Typography pb={"1rem"}>
      7.1 Complaints that are received at our end will be seen in the right perspective and would be analyzed from all possible angles.
      </Typography>
      <Typography pb={"1rem"}>
      7.2 The communication of the Company stand on any issue will be provided to the customers. Complaints that require some time for examination of issues involved will be acknowledged promptly.
      </Typography>
      <Typography pb={"1rem"}>
      7.3 The Company shall endeavor to dispose off the complaint/dispute within a period of one (1) month from date of receipt of complaint.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      8 POLICY REVISION
      </Typography>
      <Typography pb={"1rem"}>
      8.1 The aforesaid Policy will be reviewed periodically /revised as and when there are any new changes incorporated by the Company in handling complaints / grievances of the customer which includes introduction of new grievance channels, if any.
      </Typography>

    </Box>
  );
};

export default GrievanceRedressal;
