/* eslint-disable eqeqeq */
import React from "react";
import "../Section1/section1.css";
import { Box } from "@mui/material";
import check4 from "../../assets/images/check4_5.jpg"


const AboutSection = () => {

  return (
    <div>
      <section
        className="about-one "
        id="about-id"
      >
      <Box sx={{
        background:`url(${check4})`,
        backgroundRepeat:'no-repeat',
        backgroundSize:'cover',
        backgroundPosition:'10% 10%',
        height:'300px',
        "@media (max-width:900px)": {
          backgroundSize: "cover",
          backgroundPosition: "10% 10%",
          backgroundRepeat: "no-repeat",
          height: "300px",
        },
      }}>

      </Box>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="about-one__content-about">
                <div className="block-title text-center">
                  <p>About us</p>
                  <h2>Loans At Your Best</h2>
                </div>
                <p className="about-one__text text-center">
                  Your Journey From “Less To More Than”
                </p>
                <p className="only-p">
                  Matsya Fincap, a noted financial institution honoured for its
                  hassle free and trusted lending approach has been continuously
                  serving you the finance at your need and customization. With
                  your trust and immense support since 2015, we have been able
                  to achieve a milestone in the financial sphere with the vision
                  of providing Loans At Your Best. Starting from personal loans
                  to business loans, Matsya has been extending with AI and
                  advancements, delivering you all the diversities under one
                  roof and has become a one stop solution for the borrowers to
                  meet their financial needs and make loans accessible on demand
                  with zero barrier and least documentation. Being your reliable
                  fintech partner, we aim at empowering individuals and
                  businesses by providing them tailored financial solutions with
                  minimum interest rates and always acceptable customizations
                  according to your need.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutSection;
