import { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TemporaryDrawer from "./Components/EmiCalculator/EmiCalCulator";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import NavBar from "./Components/NavBar/NavBar";
import ContactForm from "./Components/ContactFrom/ContactForm";
import Home from "./Components/home/Home";
import About from "./Components/About/About";
import Services from "./Components/Services/Services";
import Contact from "./Components/Contact/Contact";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsCondition from "./Pages/TermsCondition";
import InterestRatePolicy from "./Pages/InterestRatePolicy";
import GrievanceRedressal from "./Pages/GrievanceRedressal";
import FairPracticeCode from "./Pages/FairPracticeCode";
import CreditLab from "./Pages/CreditLab";

function App() {
  const [open, setOpen] = useState(false);
  const handleToggle = () => setOpen(!open);

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(!modalOpen);

  return (
    <>
      <TemporaryDrawer handleToggle={handleToggle} open={open} handleModalOpen={handleModalOpen} />
      <Header />
      <NavBar handleToggle={handleToggle} handleModalOpen={handleModalOpen} />
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Home handleModalOpen={handleModalOpen} />
              </>
            }
          />
          <Route
            path="/about"
            element={
              <>
                <About />
              </>
            }
          />
          <Route
            path="/services"
            element={
              <>
                <Services />
              </>
            }
          />
          <Route
            path="/contact"
            element={
              <>
                <Contact />
              </>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <>
                <PrivacyPolicy />
              </>
            }
          />
          <Route
            path="/terms-and-conditions"
            element={
              <>
                <TermsCondition />
              </>
            }
          />
          <Route
            path="/interest-rate"
            element={
              <>
                <InterestRatePolicy />
              </>
            }
          />
          <Route
            path="/grievance-redressal"
            element={
              <>
                <GrievanceRedressal />
              </>
            }
          />
          <Route
            path="/fair-practice"
            element={
              <>
                <FairPracticeCode />
              </>
            }
          />
          {/* <Route
            path="/credit-lab"
            element={
              <>
                <CreditLab />
              </>
            }
          /> */}
        </Routes>
      </Router>
      <Footer handleModalOpen={handleModalOpen} />
      <ContactForm handleModalOpen={handleModalOpen} modalOpen={modalOpen} />
    </>
  );
}

export default App;
