import { Box, Typography } from "@mui/material";
import React from "react";

const TermsCondition = () => {
  return (
    <Box
      className="container"
      py={"1rem"}
      sx={{
        "& p": {
          fontFamily:
            "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
          textAlign: { sm: "start", xs: "justify" },
        },
      }}
    >
      <div className="block-title text-center">
        <Typography
          component={"h2"}
          sx={{ fontSize: { sm: "2.5rem", xs: "1.5rem" } }}
        >
           Terms & Conditions 
        </Typography>
      </div>
      <Typography pb={"1rem"} fontWeight={"600"}>
      1 INTRODUCTION
      </Typography>
      <Typography pb={"1rem"}>
      1.1 The website www.Matsyafincap.com (“Website”) and other related internet-based applications designed to be used on smartphones, tablets or other mobile devices (“App”) or other applications, software, digital media, storage medium, or functionalities related to our brand (collectively referred to as “Platform”) is owned and operated by Matsya Fincap Private Limited, a company incorporated under the Companies Act, 2013 having its registered office at KHASRA NO. 1141, VILLAGE BHADKOL ALWAR Alwar RJ 301001 IN (hereinafter referred to as “Company”, “we”, “us” “our” or “Matsya”).
      </Typography>
      <Typography pb={"1rem"}>
      1.2 “Customer(s)" or "you" or “User” or "End-Users" shall mean any person who accesses, downloads, uses, or views the Platform and the services.
      </Typography>
      <Typography pb={"1rem"}>
      1.3 These Terms and Conditions (“Terms”) contained on this webpage shall govern the use of this Platform, including all pages within this Platform. By using this Platform, you expressly accept all terms and conditions contained herein in full. This Platform is meant for information and financial transactions and may require entering into legal contracts to digitally sign transactions.
      </Typography>
      <Typography pb={"1rem"}>
      1.4 To the extent applicable, these Terms may be treated as one of the Finance Documents. For these Terms, the term “Finance Documents” shall mean the documents executed by Matsya with its borrowers/obligors in connection with the financial assistance provided by us.
      </Typography>
      <Typography pb={"1rem"}>
      1.5 These Terms are electronic records as per the Information Technology Act, 2000, and rules made there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. These Terms are published under the provisions of Rule 3(1) of the Information Technology (Intermediaries Guidelines) Rules, 2011 that require publishing of the privacy policy and Terms of use for access or usage of the Platform.
      </Typography>
      <Typography pb={"1rem"}>
      1.6 We reserve the right, at our sole discretion, to change, modify, add or remove portions of these Terms at any time without any prior written notice to you. Please review these Terms periodically for updates/changes. Your continued use of the Platform following the posting of changes will mean that You accept and agree to all revisions made from time to time.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      2 ELIGIBILITY
      </Typography>
      <Typography pb={"1rem"}>
      2.1 The use of the Platform is available only to persons who can form legally binding contracts under applicable laws of India. Persons who are “incompetent to contract” within the meaning of the Indian Contract Act, of 1872 including minors, un-discharged insolvents, etc. are not eligible to use the Platform. If you are a minor i.e., under the age of eighteen (18) years, you shall not register on the Platform and shall not transact on or use the Platform. As a minor, if you wish to use or transact on the Platform, such use or transaction may be made by your legal guardian or parents on the Platform. Matsya reserves the right to terminate your membership and/or refuse to provide you with access to the Platform if it is brought to Matsya's notice or if it is discovered that you are under the age of eighteen (18) years. You further acknowledge and agree that you are not barred or otherwise legally prohibited from accessing or using the Platform under the laws of India or any other applicable law.
      </Typography>
      <Typography pb={"1rem"}>
      2.2 In addition to these general requirements, you may also have to fulfill additional criteria to be able to use the services available on the Platform.
      </Typography>
      <Typography pb={"1rem"}>
      2.3 If you violate any of the Terms, Matsya may terminate your membership, delete or suspend your account on the Platform and any related information and/or prohibit you from using or accessing the Platform at any time in its sole discretion.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      3 INTELLECTUAL PROPERTY RIGHTS
      </Typography>
      <Typography pb={"1rem"}>
      3.1 You understand, acknowledge, and agree that the Company is the sole owner of all rights, title, and interest, including all intellectual property rights in the content, Platform, products, services, logos, trade names, brand names, designs and any necessary software used in connection with the Platform.
      </Typography>
      <Typography pb={"1rem"}>
      3.2 There may be proprietary logos, service marks, and trademarks found on the Platform whether owned/used by the Company or otherwise. By displaying them on the Platform, the Company is not granting you any license to utilize the proprietary logos, service marks, or trademarks. Any unauthorized use of the same may violate applicable intellectual property laws.
      </Typography>
      <Typography pb={"1rem"}>
      3.3 Matsya grants you a limited license to access and use the Platform for personal, non-commercial use. This license is non-transferable and it does not confer any right to download, copy, create a derivative work from, modify, reverse engineer, reverse assemble or otherwise attempt to discover any source code, sell, assign, sublicense, grant a security interest in or otherwise transfer any right in the Platform or the services provided thereon. You do not have the right to use any of Matsya’s trade names, trademarks, service marks, logos, domain names, and other distinctive brand features. You may use information on the services made available on the Platform for downloading, provided that you (i) do not remove any proprietary notice language in all copies of such documents, (ii) use such information only for your personal, non-commercial informational purpose and do not copy or post such information on any networked computer or broadcast it in any media, (iii) make no modifications to any such information; and (iv) do not make any additional representations or warranties relating to such documents. Except as expressly provided in the Terms, no part of the Platform may be copied, reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted or distributed in any way (including “mirroring”) to any other computer, server, website or another medium for publication or distribution or for any commercial enterprise, without Matsya's express prior written consent. Any unauthorized use of the Platform may result in immediate revocation of the license granted to you by Matsya.
      </Typography>
      <Typography pb={"1rem"}>
      3.4 You understand and acknowledge that the Platform is owned by the Company. Nothing under the Terms shall be deemed to be a transfer in ownership, rights, or title, from the Company to you or any third party, in the Platform. You are entitled to avail of the services offered by the Company during the validity of your registration with the Company.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      4 DATA PROTECTION
      </Typography>
      <Typography pb={"1rem"}>
      4.1 The storage, usage and transmission of all information obtained by it through the Platform, shall at all times be in accordance with the Information Technology Act, 2000 read with Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 and all other applicable laws.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      5 USER’S OBLIGATIONS FOR ACCESSING THE PLATFORM
      </Typography>
      <Typography pb={"1rem"}>
      5.1 You may only use the Platform to avail the services that may be offered on the Platform by Matsya. You shall not use the Platform to make any fraudulent transactions. You agree not to use the Platform for any purpose that is unlawful, illegal or forbidden by the Terms, or any applicable laws. Matsya may, at its sole discretion, at any time and without prior notice or liability, impose additional requirements and restrictions or suspend, terminate or restrict your access to the Platform (or any portions thereof) if it comes to Matsya’s notice that you have breached the Terms, privacy policy and/or any applicable laws.
      </Typography>
      <Typography pb={"1rem"}>
      5.2 You must be the sole owner and User of your User account. You cannot have more than one User account at any point of time. You are responsible for maintaining the confidentiality and security of your User account, password, and activities that occur in or through your User account and for restricting access to your device so as to prevent unauthorized access to your User account.
      </Typography>
      <Typography pb={"1rem"}>
      5.3 The User must notify Matsya at support@Matsyafincap.com as soon as reasonably possible upon becoming aware of any actual or attempted unauthorized access to the Platform or any unauthorized transaction or attempt to execute an unauthorized transaction in connection with the services or any other circumstance that might be reasonably likely to result in any prejudice to the security of the Platform or User account login details.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      5.4 You agree, undertake, and confirm not to host, display, upload, modify, publish, transmit, store, update or share any information that:
      </Typography>
      <Typography pb={"1rem"} >
      a) belongs to another person and to which you do not have any right to;
      </Typography>
      <Typography pb={"1rem"} >
      b) is defamatory, obscene, pornographic, paedophilic, invasive of another's privacy, including bodily privacy, insulting or harassing on the basis of gender, libellous, racially or ethnically objectionable, relating or encouraging money laundering or gambling, or otherwise inconsistent with or contrary to the laws in force;
      </Typography>
      <Typography pb={"1rem"} >
      c) is harmful to child;
      </Typography>
      <Typography pb={"1rem"} >
      d) infringes any patent, trademark, copyright or other proprietary rights;
      </Typography>
      <Typography pb={"1rem"} >
      e) violates any law for the time being in force;
      </Typography>
      <Typography pb={"1rem"} >
      f) deceives or misleads the addressee about the origin of such messages or communicates any information that is grossly offensive or menacing in nature;
      </Typography>
      <Typography pb={"1rem"} >
      g) impersonate another person;
      </Typography>
      <Typography pb={"1rem"} >
      h) contains software viruses or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any computer resource;
      </Typography>
      <Typography pb={"1rem"} >
      i) threatens the unity, integrity, defense, security, or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offense or prevents investigation of any offense or is insulting any other nation;
      </Typography>
      <Typography pb={"1rem"} >
      j) is patently false and untrue, and is written or published in any form, with the intent to mislead or harass a person, entity or agency for financial gain or to cause any injury to any person.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      5.5 You further agree, undertake, and confirm not to:
      </Typography>
      <Typography pb={"1rem"}>
      a) use the Platform or the services for committing fraud, embezzlement, money laundering, or for any unlawful and/or illegal purposes;
      </Typography>
      <Typography pb={"1rem"}>
      b) to reproduce, duplicate, copy, sell, resell, or exploit any portion of the Platform;
      </Typography>
      <Typography pb={"1rem"}>
      c) use the Platform to harm or injure any third party;
      </Typography>
      <Typography pb={"1rem"}>
      d) forge headers or otherwise manipulate identifiers to disguise the origin of any content transmitted through the Platform;
      </Typography>
      <Typography pb={"1rem"}>
      e) upload, post, email, transmit, or otherwise make available any content that you do not have a right to make available under any law or contractual or fiduciary relationships (such as inside information, proprietary and confidential information learned or disclosed as part of employment relationships or under non-disclosure agreements);
      </Typography>
      <Typography pb={"1rem"}>
      f) upload, post, email, transmit or otherwise make available on the Platform, any unsolicited or unauthorized advertising, promotional materials, "junk mail," "spam", "chain letters," "pyramid schemes," or any other form of solicitation;
      </Typography>
      <Typography pb={"1rem"}>
      g) interfere with or disrupt the Platform or servers or networks connected to the Platform, or disobey any requirements, procedures, policies or regulations of networks connected to the Platform.
      </Typography>
      <Typography pb={"1rem"}>
      5.6 We reserve the right, but have no obligation, to monitor the materials posted on the Platform. Notwithstanding this right, you remain solely responsible for the content of the materials you post on the Platform. In no event shall Matsya assume or have any responsibility or liability for any content posted or for any claims, damages or losses resulting from use of content and/or appearance of content on the Platform.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      6 Use of Information and Materials
      </Typography>
      <Typography pb={"1rem"}>
      6.1 The content (material, information, data, money market movements, news items, texts, graphics, links etc.) contained on this Platform is provided for general information only and should not be used as a basis for making business/commercial decisions. You are advised to exercise due caution and/or seek independent advice before availing any facility or entering into any investment or financial obligation based on the content contained on this Platform.
      </Typography>
      <Typography pb={"1rem"}>
      The content on this Platform should not be regarded as an offer, solicitation, invitation, advice or recommendation to buy or sell investments, securities or any other instrument or financial products / schemes of Matsya, or any of its subsidiaries/affiliates.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      7 PRODUCTS AND SERVICES
      </Typography>
      <Typography pb={"1rem"}>
      7.1 Products and services are available only at the discretion of Matsya and its fellow subsidiaries/ digital lending partners, subject to the individual contractual terms and conditions of products and services on which they are offered and such products and services may be withdrawn or amended at any time without notice. The full range of products or services may not be available in all locations. All rates, charges and fees quoted / stated for various financial products and schemes and interest rates cited as examples of rates which may be in effect from time to time are indicative rates only and are subject to change at any time at our sole discretion as the case may be, and in accordance with applicable laws.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      7.2 Loan Terms:
      </Typography>
      <Typography pb={"1rem"}>
      7.2.1 Loan: The loan provided shall be the amount stated in the sanction letter which shall be strictly in accordance with the terms and conditions mentioned herein and based on other documents executed by you.
      </Typography>
      <Typography pb={"1rem"}>
      7.2.3 Disbursement: Disbursement of loan will be done to your designated bank account by way of direct bank transfer via NEFT, IMPS or through such other modes as deems fit in our absolute discretion.
      </Typography>
      <Typography pb={"1rem"}>
      7.2.4 Repayment: You shall pay all installments, inclusive of interest, on a monthly basis on the scheduled due date, from the first due date to the last due date as mentioned in the sanction letter/ loan agreements on the first presentation. In order to facilitate such repayment, we are providing various options that can be relied upon by you, including, without limitation, debit card payment, net banking payment, direct bank transfer, etc. In order to further secure such payments, we rely on securest payment gateways. If we confirm your request for refund, subject to the terms and conditions mentioned herein or elsewhere, we will send you an e-mail or any other convenient mode seeking the details required to refund the amount which may include your bank account details such as the account number and the IFS code of the branch in question. Kindly note that it will take us a minimum of about 48-72 working hours from the receipt of all such information to process the refund and initiate the transfer. We reiterate once again that this will be done only where excess equated monthly installment (EMI) has been collected from customers provided that the customer has never delayed the payment subject to the discretion of the senior management of the Company.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      8 SUSPENSION/TERMINATION OF SERVICES
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      8.1 The Company reserves its rights to terminate or suspend your User Account in the event:
      </Typography>
      <Typography pb={"1rem"}>
      a) you breach any provision of the Terms and the Privacy Policy;
      </Typography>
      <Typography pb={"1rem"}>
      b) the Company is required to do so under applicable law;
      </Typography>
      <Typography pb={"1rem"}>
      c) the Company chooses to discontinue the services being offered or discontinue to operate the Platform;
      </Typography>
      <Typography pb={"1rem"}>
      d) the license granted to use the Platform expires;
      </Typography>
      <Typography pb={"1rem"}>
      e) of non-payment of Outstanding Amount(s) by you to us.
      </Typography>
      <Typography pb={"1rem"}>
      8.2 The Company reserves its right to track you, even when you have uninstalled the App, until all your obligations, including but not limited to payment of the outstanding amount(s) is in subsistence.
      </Typography>
      <Typography pb={"1rem"}>
      8.3 Matsya may wish to stop providing services through the Platform, and may terminate your use of it at any time without any prior notice to you or liability to Matsya, although Matsya shall endeavor to provide you with prior written notice. Unless Matsya informs you otherwise, upon termination, you shall stop using the Platform. Matsya reserves the right to suspend or cease providing any Service and shall have no liability or responsibility to you in any manner whatsoever if it chooses to do so.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      9 THIRD PARTY LINKS IN THE PLATFORM
      </Typography>
      <Typography pb={"1rem"}>
      9.1 The Platform may contain links to other websites owned and operated by third parties who are not related to the Platform ("Linked Websites"). The Linked Websites are not under the control of the Company and the Company shall not be responsible for the content of any Linked Websites or any hyperlink contained in a Linked Website and makes no representation or warranty with respect to the content of any such Linked Websites.
      </Typography>
      <Typography pb={"1rem"}>
      9.2 The Platform provides these links to you as a convenience only and the inclusion of any link does not imply any endorsement of the Linked Website by the Company. Your access or use of such Linked Website is entirely at your own risk. The Company shall not be a party to any transaction between you and the Linked Website. Your use of a Linked Website is subject to the terms and conditions of that respective Linked Website.
      </Typography>
      <Typography pb={"1rem"}>
      9.3 The Platform may also contain third party advertisements, if any. The display of such advertisements does not in any way imply an endorsement or recommendation by/of the relevant advertiser, its products or services. You shall independently refer to the relevant advertiser for all information regarding the advertisement and its products and/or services. The Company accepts no responsibility for any interaction between you and the relevant third party and is released from any liability arising out of or in any way connected with such interaction.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      10 DISCLAIMER OF WARRANTIES
      </Typography>
      <Typography pb={"1rem"}>
      10.1 You agree that your use of the Platform is being conducted voluntarily and after due research about the Platform. Matsya is not responsible for any adverse consequences arising out your use of the Platform if such consequences are not traceable to any breach on the part of Matsya.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      10.2 You expressly understand and agree that:
      </Typography>
      <Typography pb={"1rem"}>
      10.2.1 The Platform, all content and services included on or otherwise made available to you through the Platform are provided on an “as is” and on an “as available” basis, without any representation or warranties, express or implied except otherwise specified in writing. Matsya expressly disclaims all warranties of any kind, whether express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, title, non-infringement, and security and accuracy, as well as all warranties arising by usage of trade, course of dealing, or course of performance.
      </Typography>
      <Typography pb={"1rem"}>
      10.2.2 Your use of the services and the Platform is at your sole risk.
      </Typography>
      <Typography pb={"1rem"}>
      10.2.3 Matsya shall not be liable for the loss and/or damage of the confidential information or data of the User arising as a result of an event or a series of related events, that is beyond the control of Matsya, including failures of or problems with the internet or part of the internet, attempted hacker attacks, denial of service attacks and/or viruses or other malicious software attacks or infections.
      </Typography>
      <Typography pb={"1rem"}>
      10.2.4 Any material downloaded or otherwise obtained through the access or use of the Platform, is at your own discretion and risk and that you will be solely responsible for any damage to your computer system, electronic data or loss of data that results from the download of any such material. No advice or information, whether verbal or written, obtained by you from Matsya,for the services or through the Platform shall create any warranty not expressly stated in the Terms.
      </Typography>
      <Typography pb={"1rem"}>
      10.2.5 The services are intended for personal, non-commercial use. You shall be solely responsible for the use, misuse, improper usage of the services and the Platform. Matsya shall not be liable for any damages accruing out of the use of the services which have not been expressly stipulated under the Terms and Conditions.
      </Typography>
      <Typography pb={"1rem"}>
      10.2.6 Matsya makes no warranty, including implied warranty, and expressly disclaims any obligation, that: (a) the contents are and will be complete, exhaustive, accurate or suitable to your requirements; (b) The Platform or the services will meet your requirements or will be available on an uninterrupted, timely, secure, or error-free basis; (c) the results that may be obtained from the use of the Platform or services will be accurate or reliable.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      11 INDEMNITY
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      11.1 You agree to indemnify and hold the Company, and its subsidiaries, affiliates, officers, agents, co-branders or other partners, and employees, harmless from and against any costs, claims, charges, expenses, losses, damages or demand, including attorneys’ fees, made by any third party due to or arising out of:
      </Typography>
      <Typography pb={"1rem"}>
      a. your breach of the Terms and Conditions or Privacy Policy;
      </Typography>
      <Typography pb={"1rem"}>
      b. your breach of any rights of other users of the Platform;
      </Typography>
      <Typography pb={"1rem"}>
      c. your use or misuse of the Platform or the services;
      </Typography>
      <Typography pb={"1rem"}>
      d. your violation of applicable laws.
      </Typography>
      <Typography pb={"1rem"}>
      11.2 This clause shall survive termination of the Terms.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      12 LIMITATIONS OF LIABILITY
      </Typography>
      <Typography pb={"1rem"}>
      12.1 You expressly understand and agree that the Company, including its directors, officers, employees or representatives shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if the Company has been advised of the possibility of such damages), resulting from; (a) use or the inability to avail the services (b) inability to use the Platform (c) failure or delay in providing the services or access to the Platform (d) any performance or non-performance by the Company (e) any damages to or viruses that may infect your electronic devices or other property as the result of your access to the Platform or your downloading of any content from the Platform and (f) server failure or otherwise or in any way relating to the services.
      </Typography>
      <Typography pb={"1rem"}>
      12.2 In no event shall the Company’s total cumulative liability arising from or relating to these exceed the amount of fees received from the User by the Company.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      13 UPDATE AND REVISION OF TERMS
      </Typography>
      <Typography pb={"1rem"}>
      13.1 Matsya Fincap Private Limited is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review such Terms regularly.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      14 AGREEMENT
      </Typography>
      <Typography pb={"1rem"}>
      14.1 These Terms, including any legal notices and disclaimers contained on this Platform, constitute the entire agreement between Matsya Fincap Private Limited and you concerning your use of this Platform and supersede all prior agreements and understandings concerning the same. If any clause of these Terms is or becomes illegal, invalid, or unenforceable by any court of competent jurisdiction, such findings shall not invalidate the remainder of these Terms.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      15 DIGITAL CONTRACTS
      </Typography>
      <Typography pb={"1rem"}>
      15.1 This Platform provides financial services including advancing of loans to eligible users using our digital lending platform partner(s). This will require the execution of loan agreements online. The agreement in full will be displayed to the user on the Platform and needs to be e-signed /digitally signed by the user as provided by the Electronic Signature or Electronic Authentication Technique and Procedure Rules 2015 under the Indian Information Technology Act 2000, and shall be binding on the user.
      </Typography>
      <Typography pb={"1rem"}>
      15.2 If You stop, cease, or discontinue to use or avail the services provided by Us, through deletion or uninstallation or otherwise, till the event that Your obligations to pay the outstanding amount(s) to Us exist; deletion, uninstallation, discontinuation of our facilities, shall not release you from the responsibility, obligation, and liability to repay the facilities or outstanding amount(s) or any other charges payable to Us.
      </Typography>
      <Typography pb={"1rem"}>
      15.3 You understand and acknowledge that you shall be solely responsible for all the activities that occur under Your User account while availing the facilities. You undertake that We shall not be responsible and liable for any claims, damages, and disputes arising out of use or misuse of the facilities. By usage of the facilities, you shall be solely responsible for maintaining the confidentiality of the User account and for all other related activities under Your User account. We reserve the right to accept or reject Your registration at its sole discretion for the services without obligation of explanation.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      16 MISCELLANEOUS
      </Typography>
      <Typography pb={"1rem"}>
      16.1 Each of the provisions of the Terms is severable from the others and if one or more of them becomes void, illegal, or unenforceable, the remainder will not be affected in any way.
      </Typography>
      <Typography pb={"1rem"}>
      16.2 The rights of Matsya, its affiliates, associates, and agents under the Terms may be exercised as often as necessary and are cumulative and not exclusive of their rights under any applicable law. Any delay in the exercise or non-exercise of any such right is not a waiver of that right.
      </Typography>
      <Typography pb={"1rem"}>
      16.3 The User may not assign, part with or otherwise transfer any right or benefit under any provision of the Terms without Matsya's prior written consent.
      </Typography>
      <Typography pb={"1rem"}>
      16.4 Matsya may assist or cooperate with authorities in any jurisdiction concerning any direction or request to disclose personal or other information regarding any User or the use of the Platform, content, or services.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      17 FORCE MAJEURE
      </Typography>
      <Typography pb={"1rem"}>
      17.1 Matsya shall not be held responsible for damage, loss, non-availability of the Platform or its services, or deficiency in the provision of the Platform or its services, resulting directly or indirectly due to a fire, earthquake, flood, epidemic, strike, lockout, labor unrest, riot, civil disturbance, war, civil commotion, act of God, act of terrorism, court order, change in law, or any other circumstance which is beyond the reasonable control of Matsya.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      18 PRIVACY POLICY
      </Typography>
      <Typography pb={"1rem"}>
      18.1 Matsya may collect, store, use, process and transfer to third parties information provided by you at the time of registration of your User Account, or during your use of the Platform or Matsya’s services, including your personal information, under applicable data protection laws and the Privacy Policy. For more information on how Matsya collects, receives, possesses, stores, deals or handles your personal information, please see and agree to the Privacy Policy provided on the Platform. Your acceptance of the Terms, and use of the Platform, shall be deemed to constitute your acceptance of the terms of the Privacy Policy.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      19 GRIEVANCE REDRESSAL
      </Typography>
      <Typography pb={"1rem"}>
      19.1 If you have any concerns regarding the Platform, these Terms or the services, please contact the grievance officer whose name and contact details are available in the Grievance Redressal Policy.
      </Typography>
      <Typography pb={"1rem"}>
      19.2 Without prejudice to Matsya's other rights under these Terms, if you breach these Terms in any way we shall be entitled to take such action as it may deem fit.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      20 GOVERNING LAWS & JURISDICTION
      </Typography>
      <Typography pb={"1rem"}>
      20.1 The Terms (and by extension, the Privacy Policy) are governed by and construed under the laws of India. By using the Platform, you hereby irrevocably consent to the jurisdiction of competent courts in India, in the event of any disputes arising out of or concerning your access to and use of the Platform.
      </Typography>
      
    </Box>
  );
};

export default TermsCondition;
