import React from "react";
import  "../Section3/section3.css";
import feature_shape_1 from "../../assets/images/shapes/feature-shape-1-1.png";
import feature_shape_2 from "../../assets/images/shapes/feature-shape-1-2.png";
import feature_shape_3 from "../../assets/images/shapes/feature-shape-1-3.png";

const ServiceSection = () => {
  return (
    <div>
      <section className="feature-one">
        <img src={feature_shape_1} alt="" className="feature-one__shape-1" />
        <img src={feature_shape_2} alt="" className="feature-one__shape-2" />
        <img src={feature_shape_3} alt="" className="feature-one__shape-3" />
      </section>
    </div>
  );
};

export default ServiceSection;
