import React, { useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const ContentPop = ({ handleModalOpen, modalOpen, data }) => {
    console.log(data,"data value")

  // eslint-disable-next-line no-unused-vars
  const [scroll, setScroll] = React.useState("paper");

  useEffect(() => {
    // AOS.init();
  }, []);

  return (
    <>
      <Box>
        <Dialog
          sx={{
            backgroundColor: "#0000003b",

            "& .MuiPaper-root": {
              backgroundColor: "#fff",
              border: "1px solid #002cffbd",
              width: "500px",
              borderRadius: "10px",
            },
          }}
          open={modalOpen}
          onClose={handleModalOpen}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" fontWeight={700} color={"#000"}>
                {data[0]?.title}
              </Typography>
              <IconButton onClick={handleModalOpen}>
                <ClearIcon sx={{ color: "#000" }} />
              </IconButton>
            </Box>
          </DialogTitle>

          <DialogContent dividers={scroll === "paper"}>
            <Box>
            <Typography component={'p'}>{data[0]?.content}</Typography>
            <Typography component={'p'}>{data[0]?.line2}</Typography>
            
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
};

export default ContentPop;
