import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    border: "none",
    padding: 0,
  },
  "& .MuiDialogActions-root": {
    padding: 0,
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle
      sx={{ m: 0, p: "12px 16px", fontFamily: "Public Sans, sans-serif" }}
      {...other}
    >
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          title="Close"
          sx={{
            position: "absolute",
            right: 8,
            color: "black",
            top: 8,
          }}
        >
          <CloseIcon
            sx={{
              color: "black",
            }}
          />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
export default function SuccessPop({ toggleSuccess, successPop, message }) {
  return (
    <div>
      <BootstrapDialog
        onClose={toggleSuccess}
        aria-labelledby="customized-dialog-title"
        open={successPop}
        sx={{
          "& .MuiDialogTitle-root": {
            borderBottom: "1px solid black",
            fontWeight: "bolder",
            fontFamily: "Public Sans, sans-serif",
          },
          "& .MuiDialog-paper": {
            width: "550px",
            border: "5px solid #fff",
            borderRadius: "10px",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent !important",
          },
        }}
      >
        <DialogContent dividers>
          <Box
            sx={{
              textAlign: "center",
              // padding: "1rem 0.5rem",
            }}
          >
            <div className="card service-wrapper1 rounded border-0 shadow p-4">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingBottom:'0.7rem'
                }}
              >
              <Typography component={'p'} sx={{
                fontWeight:600,
                fontSize:'1.3rem'
              }}>Success</Typography>
                <CloseIcon
                  sx={{
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={toggleSuccess}
                />
              </div>
              <Typography component={'hr'} sx={{paddingTop:'1rem'}} />
              <Box sx={{padding:'2rem 0rem'}}>
                <Typography component={'p'} sx={{textAlign:'center'}}>{message}</Typography>
              </Box>
 

            </div>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
