import { Box, Typography } from "@mui/material";
import React from "react";

const PrivacyPolicy = () => {
  return (
    <Box
      className="container"
      py={"1rem"}
      sx={{
        "& p": {
          fontFamily:
            "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
          textAlign: { sm: "start", xs: "justify" },
        },
      }}
    >
      <div className="block-title text-center">
        <Typography
          component={"h2"}
          sx={{ fontSize: { sm: "2.5rem", xs: "1.5rem" } }}
        >
          Privacy Policy
        </Typography>
      </div>
      <Typography pb={"1rem"}>
        This privacy policy (“Privacy Policy”) establishes the terms and
        conditions under which Matsya Fincap Private Limited (“the Company” or
        “Matsya” or “we” or “us” or “our”) with registered address at KHASRA NO.
        1141, VILLAGE BHADKOL ALWAR Alwar RJ 301001 IN., processes the personal
        information that you provide us with when using the website
        “www.Matsyafincap.com” (“theWebsite” or “site”) and other related
        internet based applications designed to be used on smart phones, tablets
        or other mobile devices (“App”) or other applications, software, digital
        media, storage medium, or functionalities related to our brand
        (collectively referred to as “Platform”).
      </Typography>
      <Typography pb={"1rem"}>
        This Privacy Policy gives you details about our data collection methods
        and usage. Users, please take note that any statements made on the
        Platform shall not be construed as an offer or promises for grant of any
        financial services.
      </Typography>
      <Typography pb={"1rem"}>
        Maintaining the security of your data is a priority at Matsya. We are
        committed to respecting your privacy rights. We pledge to handle your
        data fairly and legally at all times. We are also dedicated to being
        transparent about what data we collect about you and how we use it. By
        visiting and accessing the Platform and availing the loan services
        provided by us, you are deemed to have read, acknowledged and accepted
        all the provisions stated here in the Privacy Policy, including any
        changes we may make from time to time.
      </Typography>
      <Typography pb={"1rem"}>
        By assenting to this Privacy Policy, you consent explicitly to the
        collection, use, processing, retention and disclosure of your Personal
        Information that you provide to the Company. This Privacy Policy is
        incorporated into and subject to the Terms and Conditions of the
        Platform. You are providing the Personal Information out of your free
        will and with informed consent.
      </Typography>
      <Typography pb={"1rem"}>
        In this Privacy Policy, “Personal Information” means information
        provided by you that can be used to directly or indirectly identify you
        including through your mobile number or email address, either from that
        information alone or from that information combined with other
        information the Platform has/may have access to about you. Such Personal
        Information may include your name, email address, date of birth,
        residence address, telephone number, your financial information, device
        information, identity information and/or location information.
      </Typography>
      <Typography pb={"1rem"}>
        If you do not agree to this Privacy Policy or any part thereof, please
        do not use/ access/ download or install the Platform or any part
        thereof.
      </Typography>
      <Typography pb={"1rem"}>
        The Platform is not expected to be used by any person below 18 years of
        age.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
        This Privacy Policy provides you with information about:
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
        1 What Personal Information We Collect
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
        1.1 We will only collect information that is necessary and needed for
        the specified, explicit and lawful purposes of rendering our services
        and will not further process it in a manner that is incompatible with
        those purposes. We may collect the following types of information:
      </Typography>
      <Typography pb={"1rem"}>
        1.1.1 Information you provide to us or upload on our Platform including
        your contact information (eg.: name, date of birth, gender, photo,
        postal or e-mail address, fax number, phone number, education,
        occupation, account details, identification documents, banking
        information such as salary slips, bank statements, PAN card, bank
        account number, payment information, or any other information/documents
        for KYC compliance).
      </Typography>
      <Typography pb={"1rem"}>
        1.1.2 Information from credit bureaus and customer service providers to
        help Matsya with customer verification and diligence required for Matsya
        and partners.
      </Typography>
      <Typography pb={"1rem"}>
        1.1.3 Location: We collect and monitor the information about the
        location of your device to check serviceability of your loan
        application, prevent fraudulent activity and also provide customised
        loan offers.
      </Typography>
      <Typography pb={"1rem"}>
        1.1.4 Social Media: We collect your public profile data depending on the
        social media or networking platform used like Facebook/LinkedIn etc. to
        login to our Platform. The information collected from your social media
        networking account shall be clearly displayed to you at the time of
        login to our Platform.
      </Typography>
      <Typography pb={"1rem"}>
        1.1.5 Financial information: We may collect information related to your
        transactions on the Platform such as loan details, transaction history,
        and repayment details.
      </Typography>
      <Typography pb={"1rem"}>
        1.1.6 Details required for administration or services and product
        improvement.
      </Typography>
      <Typography pb={"1rem"}>1.1.7 Feedback given by you.</Typography>
      <Typography pb={"1rem"}>
        1.2 This list is not exhaustive and, in specific instances we may need
        to collect additional data for the purposes set out in this Privacy
        Policy. However, one time access may be required for camera, microphone,
        location or any other facility necessary for the purpose of on-boarding/
        KYC requirements.
      </Typography>
      <Typography pb={"1rem"}>
        1.3 We may collect other types of Personal Information in exceptional
        circumstances only. If we do need to process additional types of
        Personal Information about you, we will make sure that you are informed
        about the processing of your Personal Information and that there is a
        valid legal basis for doing so (e.g. your consent).
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
        2 Collection of Personal Information and Processing Purposes
      </Typography>
      <Typography pb={"1rem"}>
        2.1 Please bear in mind that before availing any services on the
        Platform, you must read this Privacy Policy. You may choose not to
        provide certain Personal Information or withdraw the consent or ask us
        to delete such information collected or restrict disclosure to third
        parties subsequently with respect to such Personal Information by
        sending a written intimation. However, not providing certain compulsory
        information or withdrawing the consent may mean that it will not be
        possible to manage your registration as a user or to use certain
        functionalities available through the Platform.
      </Typography>
      <Typography pb={"1rem"}>
        2.2 You hereby guarantee that the Personal Information provided is true
        and exact, and agree to report any change or modification thereto. If
        you provide us with the Personal Information of third parties, you shall
        be responsible for having informed them and obtained their consent for
        these details to be provided for the purposes indicated in the
        corresponding provisions of this Privacy Policy. Any loss or damage
        caused to the Platform, to Company or to any third party through the
        communication of erroneous, inaccurate or incomplete information on the
        registration forms shall be your sole responsibility.
      </Typography>
      <Typography pb={"1rem"}>
        2.3 The Personal Information provided by you will be collected and
        retained, on behalf of our Lending Partner.
      </Typography>
      <Typography pb={"1rem"}>
        2.4 You hereby consent that we may process and disclose Personal
        Information to our affiliates, third party service providers and credit
        bureaus for the purposes stated in this Privacy Policy.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
        2.5 The Company shall use the Personal Information for the following
        purposes:
      </Typography>
      <Typography pb={"1rem"}>
        2.5.1 To provide products you have requested;
      </Typography>
      <Typography pb={"1rem"}>
        2.5.2 To provide services you have requested;
      </Typography>
      <Typography pb={"1rem"}>
        2.5.3 To provide information to our partners to assist in making, credit
        decisions about you;
      </Typography>
      <Typography pb={"1rem"}>
        2.5.4 To help investigate violations of our terms of use or to defend
        against legal claims;
      </Typography>
      <Typography pb={"1rem"}>
        2.5.5 To disclose the information under special circumstances such as
        compliance with the applicable local law, court summons, court orders,
        requests/order from legal authorities or law enforcement agencies
        requiring such disclosure;
      </Typography>
      <Typography pb={"1rem"}>
        2.5.6 Search credit bureaus and fraud prevention agencies;
      </Typography>
      <Typography pb={"1rem"}>
        2.5.7 To get in touch with you when necessary and contact you by email,
        SMS, letter, telephone or in any other way about our products and
        services;
      </Typography>
      <Typography pb={"1rem"}>
        2.5.8 To identify, prevent, detect or tackle fraud, money laundering,
        terrorism and other crimes;
      </Typography>
      <Typography pb={"1rem"}>
        2.5.9 To form a view of you as an individual and to identify, develop or
        improve products, that may be of interest to you;
      </Typography>
      <Typography pb={"1rem"}>
        2.5.10 Provide information to independent external bodies such as
        governmental departments and agencies, universities and similar to carry
        out research;
      </Typography>
      <Typography pb={"1rem"}>
        2.5.11 Perform other administrative and operational purposes including
        the testing of systems;
      </Typography>
      <Typography pb={"1rem"}>2.5.12 Trace your whereabouts;</Typography>
      <Typography pb={"1rem"}>
        2.5.13 Recover any payments you owe to Matsya or partners; and
      </Typography>
      <Typography pb={"1rem"}>
        2.5.14 Comply with our regulatory obligations;
      </Typography>
      <Typography pb={"1rem"}>2.5.15 To show you advertisements;</Typography>
      <Typography pb={"1rem"}>
        2.5.16 Contact you as a survey respondent;
      </Typography>
      <Typography pb={"1rem"}>
        2.5.17 To maintain records under applicable law or a may apply to
        pursuant to agreements executed by Matsya;
      </Typography>
      <Typography pb={"1rem"}>
        2.5.18 Carry out, monitor and analyse our business, carry out market
        research, business, and statistical analysis and also direct our efforts
        for product improvement;
      </Typography>
      <Typography pb={"1rem"}>2.5.19 Carry out audits;</Typography>
      <Typography pb={"1rem"}>
        2.5.20 Collecting user feedback. The feedback you choose to provide is
        valuable in helping the Platform make improvements to our services. In
        order to follow up on the feedback that you choose to provide, the
        Platform may correspond with you using the Personal Information that you
        have provided and may keep records of the same.
      </Typography>
      <Typography pb={"1rem"}>
        2.5.21 Sending notices. From time to time, we may use your Personal
        Information to send important notices, such as communications about
        changes to our terms, conditions and policies.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
        2.6 We may disclose your Personal Information:
      </Typography>
      <Typography pb={"1rem"}>
        2.6.1 to companies that are affiliated with us.
      </Typography>
      <Typography pb={"1rem"}>
        2.6.2 to third-party service providers who provide services to Matsya,
        such as sourcing of customer, loan recovery services, website hosting,
        data analysis, payment processing, infrastructure provision, information
        technology services, due-diligence, customer service, e-mail delivery
        services, credit card processing, auditing, training, and other similar
        services. (These third parties will be supplied with, or have access to,
        your Personal Information for the purpose of providing these services to
        us or on our behalf).
      </Typography>
      <Typography pb={"1rem"}>
        2.6.3 to the administrators. We might provide your Personal Information
        to our management team or administration department to enhance your
        experience with us and assist you 24*7.
      </Typography>
      <Typography pb={"1rem"}>
        2.6.4 to a third party in the event of any reorganization, merger, sale,
        joint venture, assignment, transfer or other disposition of all or any
        portion of our business, assets or stock (including in connection with
        any bankruptcy or similar proceedings).
      </Typography>
      <Typography pb={"1rem"}>
        2.6.5 as we believe necessary or appropriate: (i) under applicable law,
        including laws outside your country of residence; (ii) to comply with
        legal process; (iii) to respond to requests from public and government
        authorities including public and government authorities outside your
        country of residence; (iv) to enforce Terms and Conditions of the
        Platform; (v) to credit information companies (vi) to protect our
        operations or those of any of our affiliates; (vii) to protect our
        rights, privacy, safety or property, and/or that of our affiliates, you
        or others; and (viii) to allow us to pursue available remedies or limit
        the damages that we may sustain.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
        3 How We Protect Your Personal Information
      </Typography>
      <Typography pb={"1rem"}>
        3.1 All your Personal Information is stored on secure servers that are
        protected in controlled facilities situated in India. We classify your
        data based on importance and sensitivity, and ensure that your Personal
        Information has the highest security level. We maintain appropriate
        technical and organizational security safeguards designed to protect the
        Personal Information we process against accidental, unlawful or
        unauthorized destruction, loss, alteration, access, disclosure or use
        including adopting reasonable security practices and procedures
        comprising of managerial, technical, operational and physical security
        control measures as mandated under applicable Indian laws for the
        protection of Personal Information and to prevent unauthorised use or
        disclosure of Personal Information.
      </Typography>
      <Typography pb={"1rem"}>
        3.2 When you send or receive data from your device to our servers, we
        make sure they are encrypted using Secure Sockets Layer (“SSL”) and
        other algorithms.
      </Typography>
      <Typography pb={"1rem"}>
        3.3 We will take all practicable steps to safeguard your Personal
        Information. However, you should be aware that the use of the internet
        is not entirely secure, and for this reason we cannot guarantee the
        security or integrity of any Personal Information which is transferred
        from you or to you via the internet.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
        4 How Long We Retain Personal Information
      </Typography>
      <Typography pb={"1rem"}>
        4.1 Personal Information will be held for as long as it is necessary to
        fulfil the purpose for which it was collected, or as required or
        permitted by applicable laws. We shall cease to retain Personal
        Information, or remove the means by which the Personal Information can
        be associated with particular individuals, as soon as it is reasonable
        to assume that the purpose for which that Personal Information was
        collected is no longer being served by retention of the Personal
        Information, and there being no outstanding payment obligations of you
        towards the Lender.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
        5 Updates to the Privacy Policy
      </Typography>
      <Typography pb={"1rem"}>
        5.1 We keep our Privacy Policy under regular review and may update this
        Privacy Policy to reflect changes to our information practices. If we
        make any changes to our Privacy Policy, we will post the changes on the
        Platform so that you may be aware of the information we collect and how
        we use it. Such changes to our Privacy Policy shall apply from the
        effective date as set out in the notice or on the website. Your
        continued use of services on the Platform will be taken as acceptance of
        the updated Privacy Policy.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
        6 Terms and Conditions of Platform
      </Typography>
      <Typography pb={"1rem"}>
        6.1 If you choose to avail services at our Platform, your transaction
        and any dispute over privacy is subject to this Privacy Policy and our
        Terms and Conditions of Platform, including limitations on damages,
        resolution of disputes, and application of the prevailing law in India.
        If you have any concern about privacy at the Website or Platform, please
        contact us with a thorough description, and we will try to resolve it.
        If you are found to be in non-compliance with laws and regulations, the
        Terms and Conditions published on the Platform, or this Privacy Policy,
        Matsya shall have the right to immediately block your access and usage
        of the Platform and Matsya shall have the right to remove any
        non-compliant content and/or information forthwith, uploaded by you and
        shall further have the right to take appropriate recourse to such
        remedies as would be available to it under various statutes.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
        7 Cookies
      </Typography>

      <Typography pb={"1rem"}>
        7.1 Our Platform uses cookies. A cookie is a small text file, which is
        stored on your computer, mobile phone, tablets etc. in order to
        recognize your computer at recurring visits. The cookie may transmit
        information via your browser with a purpose of authenticating or
        identifying the computer (via e.g. the IP address) or the user. Cookies
        may contain information such as registration data and user preferences.
        When a server receives a request from a computer which stores a cookie
        from the website, the server is able to use the information stored in
        the cookie in combination with the information stored on the server. To
        learn more about cookies, please visit the following site, which is not
        affiliated with Matsya: http://www.allaboutcookies.org/.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
        8 Your Rights
      </Typography>
      <Typography pb={"1rem"}>
        8.1 Your rights under applicable law may include access to the Personal
        Information we process about you, the right to have such Personal
        Information corrected, updated or erased, the right to restrict the
        processing of your Personal Information, as well as the right to data
        portability. Where we have obtained your consent for the processing of
        your Personal Information, you have the right to withdraw your consent
        at any time. This will not affect the lawfulness of the processing that
        has happened based on your consent prior to the withdrawal. To exercise
        these rights, you should make a written request using our contact
        details set out below and it may result in suspension or termination of
        our services being provided to you.
      </Typography>
      <Typography pb={"1rem"}>
        8.2 You also have the right to object, at any time, to the processing of
        your Personal Information which is based on Matsya’s legitimate
        interests. Further, if you wish to remove all your Personal Information
        from the Platform, you can do so by opting out of the Platform and
        uninstalling the Platform from your system(s).
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
        9 Governing Statue
      </Typography>
      <Typography pb={"1rem"}>
        9.1 This Privacy Policy is governed by and is compliant with the
        applicable laws of India, in order to protect Personal Information of
        the end-user(s) of the services.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
        10 Grievance Officer
      </Typography>
      <Typography pb={"1rem"}>
        10.1 If you have any comments or inquiries about this Privacy Policy or
        if you would like to update information we have about you, or to
        exercise your rights, you may contact our Grievance Officer, Mr.Anil
        Kumar Agarwal, write to him at Grievance@Matsyafincap.com.
      </Typography>
    </Box>
  );
};

export default PrivacyPolicy;
