import React, { useState } from "react";
import "./section2.css";
import sevice_1_1 from "../../assets/images/services/services-1-1.png";
import sevice_1_3 from "../../assets/images/services/services-1-3.png";
import ContentPop from "./ContentPop";

const Section2 = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const handleModalOpen = () => setModalOpen(!modalOpen) 
  const [data, setData] = useState([])
  const content1 = [
    {
      title:'Personal Loan',
      content:`Matsya Fintech understands that life is full of unexpected
      expenses and unanticipated opportunities. That's why we are
      here to offer personalized loan solutions to help you
      achieve your financial goal needs anywhere and anytime with
      easily bearable interest rates and flexible repayment
      options. Whether you're looking to consolidate debt, cover
      medical expenses, fund a home improvement project, or simply
      need some extra cash, our flexible and transparent personal
      loan service is designed to meet all your diversified
      requirements. At Matsya, we care for your every small call
      for finance and thus fulfil the distinctive financial needs
      under one roof.`,
      line2:`So, Are you ready to take the next step towards financial
      freedom? Apply for a personal loan with Matsya Fincap today
      and make us your trusted fintech partner. We commit to
      provide you with a seamless and positive borrowing
      experience unlocking the possibilities with a personal loan
      tailored to your needs using our transparent terms and
      conditions and 24/7 dedicated customer support.`
    }
  ]
  const content2 = [
    {
      title:'Business Loan',
      content:`For years, Matsya Fincap has been into the service of
      understanding corporates’ financial requirements and
      aspirations and delivering a comprehensive Business Loan
      Service tailored to fuel their growth and drive success.
      Whether you're a startup looking for initial capital and
      cash for petty expenses to run your business or an
      established enterprise seeking expansion and regermination,
      we are here at every step to empower you financially and
      make your goals achieved. We provide you quick and
      competitive business loan options to get the efficiency at
      its highest , thus ensure minimum barrier and least
      documentation process every time to support your business
      journey. We serve you swift approval and disbursement with
      diverse loan products under our Business loan quarter which
      will make you feel the difference in our quality services
      and range of loan products.`,
      line2:`Now, why to think? Lets’ get connected and take the next
      step towards achieving your business goals with our
      remarkable services. Our dedicated team of financial experts
      is here to guide you at every step, ensuring that you make
      informed decisions for the prosperity of your business.
      Apply for a business loan today and experience the
      difference of financial solutions designed with your success in mind.`
    }
  ]

  const handleView = (value) =>{
    if(value === 1){
      setData(content1)
      handleModalOpen()
    }else if(value === 2){
      setData(content2)
      handleModalOpen()
    }else{
      setData("")
    }
  }
  return (
    <div>
      <section className="service-one" id="service-id">
        <div className="container">
          <div className="block-title text-center">
            <p>What We’re Offering</p>
            <h2>All Loans Services</h2>
          </div>
          <div
            className="row"
            style={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <div className="col-lg-4">
              <div className="service-one__card">
                <div className="service-one__image">
                  <img src={sevice_1_1} alt="" />
                </div>
                <div className="service-one__content">
                  <h3>
                    <a href="/pylon/servicedetails.html">Personal Loan</a>
                  </h3>
                  <p>
                  {content1[0].content.slice(0,230)}
                  </p>
                  <i className="service-one__link fa fa-long-arrow-right" onClick={()=>handleView(1)} />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="service-one__card">
                <div className="service-one__image">
                  <img src={sevice_1_3} alt="" />
                </div>
                <div className="service-one__content">
                  <h3>
                    <a href="/pylon/servicedetails.html">Business Loan</a>
                  </h3>
                  <p>
                  {content2[0].content.slice(0,250)}
                  </p>
                  <i className="service-one__link fa fa-long-arrow-right" onClick={()=>handleView(2)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContentPop handleModalOpen={handleModalOpen} modalOpen={modalOpen} data={data} />
    </div>
  );
};

export default Section2;
