import { Box, Link, Typography } from "@mui/material";
import React from "react";

const Header = () => {
  return (
    <Box sx={{ background: "#eff2f6", "@media(max-width:900px)":{
        display:'none'
    } }}>
      <div className="container">
      <Box sx={{
        display:'flex',
        justifyContent:'space-between',
        width:'100%'
      }}>
        <Box sx={{display:'flex', justifyContent:'space-between', gap:'40px'}}>
          <Box
            sx={{
              startalignItems: "center",
              display: "flex",
              background: "white",
              padding:'0.5rem 1.5rem',
              gap:'30px'
            }}
          >
            <Typography component={"p"}>
              <i className="fa fa-facebook-square" />
            </Typography>
            <Typography component={"p"}>
              <i className="fa fa-twitter" />
            </Typography>
            <Typography component={"p"}>
              <i className="fa fa-instagram" />
            </Typography>
            <Typography component={"p"}>
              <i className="fa fa-whatsapp" />
            </Typography>
          </Box>
          {/* <Box sx={{display:'flex', gap:"20px", alignItems:'center', fontSize:'0.9rem'}}>
          <Link sx={{textDecoration:'none', color:'#798490', "&:hover":{color:"#008fd5"}}}> Login /</Link>
          <Link sx={{textDecoration:'none', color:'#798490', "&:hover":{color:"#008fd5"}}}>Company News /</Link>
          <Link sx={{textDecoration:'none', color:'#798490', "&:hover":{color:"#008fd5"}}}>FAQs</Link>
          </Box> */}
        </Box>

        <Box sx={{
            display:'flex',
            gap:'20px',
            alignItems:'center'
        }}>
          <Link sx={{gap:'5px', display:'flex', textDecoration:'none ! important'}}>
            <Typography component={"span"} sx={{
                "& i:hover":{
                    color:'black',
                },
            }}>
              <i className="fa fa-envelope " />
            </Typography>
            <Typography component={"span"} sx={{textDecoration:'none', color:'#798490',"&:hover":{color:"#008fd5"}}}>connectmatsya@gmail.com</Typography>
          </Link>
          <Link sx={{gap:'5px', display:'flex', textDecoration:'none ! important'}}>
            <Typography component={"span"} sx={{
                "& i:hover":{
                    color:'black'
                },
            }}>
              <i className="fa fa-clock-o" />
            </Typography>
            <Typography component={"span"} sx={{textDecoration:'none', color:'#798490',"&:hover":{color:"#008fd5"}}}>
              Mon - Sat 10:00 AM - 6:00 PM
            </Typography>
          </Link>
        </Box>
      </Box>
      </div>
    </Box>
  );
};

export default Header;
