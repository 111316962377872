import { Box } from "@mui/material";
import React from "react";
import Banner from "../Banner/Banner";
import Section1 from "../Section1/Section1";
import Section2 from "../Section2/Section2";
import Section3 from "../Section3/Section3";

const Home = ({handleModalOpen}) => {
  // const [modalOpen, setModalOpen] = useState(false);
  // const handleModalOpen = () => setModalOpen(!modalOpen);
  return (
    <Box>
      <Banner handleModalOpen={handleModalOpen} />
      <Section1 handleModalOpen={handleModalOpen} />
      <Section2 />
      <Section3 />
    </Box>
  );
};

export default Home;
