import React from "react";
import "./section3.css";
import feature_shape_1 from "../../assets/images/shapes/feature-shape-1-1.png";
import feature_shape_2 from "../../assets/images/shapes/feature-shape-1-2.png";
import feature_shape_3 from "../../assets/images/shapes/feature-shape-1-3.png";
import assets_icon from "../../assets/images/assets (1).png";
import bill_icon from "../../assets/images/bill.png";
import economy_icon from "../../assets/images/economy.png";

const Section3 = () => {
  return (
    <div>
      <section className="feature-one">
        <img src={feature_shape_1} alt="" className="feature-one__shape-1" />
        <img src={feature_shape_2} alt="" className="feature-one__shape-2" />
        <img src={feature_shape_3} alt="" className="feature-one__shape-3" />
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="block-title text-left">
                <p>Get to Know About</p>
                <h2>Why Choosing Us Benefits</h2>
              </div>
            </div>
            <div className="col-lg-6">
              <p className="block-text">
                Create deviation with us and step into the future with our
                state-of-the-art innovative with customized solutions especially
                tailored according to your preference and the cutting-edge
                technology which enables you unlocking the possibilities of
                growth and aiding to enhance.
              </p>
            </div>
          </div>
          <div className="row secand-div">
            <div className="col-lg-4">
              <div className="feature-one__box">
                <img
                  src={assets_icon}
                  alt="icon_image"
                  className="assets_icon_image"
                />
                <p>Vast variety of loans</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="feature-one__box">
                <img
                  src={bill_icon}
                  alt="icon_image"
                  className="assets_icon_image"
                />
                <p>Minimum Documentation</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="feature-one__box">
                <img
                  src={economy_icon}
                  alt="icon_image"
                  className="assets_icon_image-eco"
                />
                <p>Quick Processing And Disbursal</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Section3;
