import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import SuccessPop from "../SuccessPop/Successpop";
import { API_URl } from "../config/config";

const useStyles = makeStyles({
  TextField__box: {
    border: "1px solid gray",
    padding: "0.3rem 1rem",
    boxShadow: "0px 3px 5px -2px #0000002e",
    borderRadius: "8px",
    color: "black !important",
  },
  TextField__box2: {
    border: "1px solid #f5f8f863",
    padding: "0.3rem 1rem 3rem 1rem",
    boxShadow: "0px 3px 5px -2px #0000002e",
    borderRadius: "8px",
  },
  btn__bg: {
    "@media(max-width : 900px)": {
      marginTop: "2rem",
    },
  },
  contact___btn: {
    background: "linear-gradient(145deg, #3E71E3, #591EAC)",
    backgroundColor: "transparent !important",
    color: "#fff !important",
    fontFamily: "poppins !important",
    padding: "0.6rem 2rem !important",
    borderRadius: "5rem !important",
    transition: "0.5s !important",
    "&:hover": {
      transform: "translateY(-5px)",
    },
    "@media(max-width : 900px)": {
      padding: "0.8rem 1.5rem !important",
    },
  },
  logo_wrap: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo__box: {
    backgroundColor: "#fff",
    padding: "0.6rem 1rem 0rem 1rem",
    borderRadius: "12px",
    margin: "0rem 1.5rem",
    opacity: "0.9",
  },
  error: {
    color: "#f14668",
    fontSize: "14px !important",
    paddingTop: "3px",
    wordBreak: "break-all",
  },
});

const ContactForm = ({ handleModalOpen, modalOpen }) => {
  const classes = useStyles();
  const [successPop, setSuccessPop] = useState(false);
  const toggleSuccess = () => setSuccessPop(!successPop)
  const [message, setMessage] = useState("")

  // eslint-disable-next-line no-unused-vars
  const [scroll, setScroll] = React.useState("paper");

  const validationSchema = yup.object({
    fname: yup.string().required("Required").trim(),
    lname: yup.string().required("Required").trim(),
    email: yup
      .string()
      .required("Required")
      .trim()
      .test("Please enter valid email", function (value) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      }),
    phone: yup
      .string()
      .trim("Phone number should not contain spaces")
      .required("Required")
      .length(10, "Phone number should be exactly 10 digits")
      .test("is-numeric", "Please enter a numeric value", function (value) {
        // Check if the value is numeric
        return /^[0-9]+$/.test(value);
      }),
    check: yup
      .boolean()
      .oneOf([true], "You must accept the terms and conditions"),
  });

  const formik = useFormik({
    initialValues: {
      fname: "",
      lname: "",
      email: "",
      phone: "",
      check: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {  
      try {
        const response = await axios.post(`${API_URl}/register`, values, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if(response.status === 200){
          setMessage(response.data.message)
          handleModalOpen()
          toggleSuccess()
        }    
        console.log('Response:', response);
      } catch (error) {
        console.log(error.response.data.message, "message")
        console.log(error.response.status, "error")
      }
    
    },
  });

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  useEffect(() => {
    // AOS.init();
  }, []);

  return (
    <>
      <Box>
        <Dialog
          sx={{
            backgroundColor: "#0000003b",

            "& .MuiPaper-root": {
              backgroundColor: "#fff",
              border: "1px solid #002cffbd",
              width: "500px",
              borderRadius: "10px",
            },
          }}
          open={modalOpen}
          onClose={handleModalOpen}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" fontWeight={700} color={"#000"}>
                Apply For Loan
              </Typography>
              <IconButton onClick={handleModalOpen}>
                <ClearIcon sx={{ color: "#000" }} />
              </IconButton>
            </Box>
          </DialogTitle>

          <DialogContent dividers={scroll === "paper"}>
            <Box>
              <form onSubmit={formik.handleSubmit}>
                <Box className={classes.TextField__box}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    variant="standard"
                    name="fname"
                    value={formik.values.name}
                    placeholder="Enter Your First Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      style: { color: "#000" },
                      disableUnderline: true,
                    }}
                    sx={{
                      color: "#000 !important",
                    }}
                  />
                </Box>
                {formik.touched.fname && formik.errors.fname && (
                  <Typography variant="body1" className={classes.error}>
                    {formik.errors.fname}
                  </Typography>
                )}

                <Box height={20} />

                <Box className={classes.TextField__box}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    variant="standard"
                    name="lname"
                    value={formik.values.lname}
                    placeholder="Enter Your Last Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      style: { color: "#000" },
                      disableUnderline: true,
                    }}
                  />
                </Box>
                {formik.touched.lname && formik.errors.lname && (
                  <Typography variant="body1" className={classes.error}>
                    {formik.errors.lname}
                  </Typography>
                )}

                <Box height={20} />

                <Box className={classes.TextField__box}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    variant="standard"
                    type="email"
                    name="email"
                    value={formik.values.email}
                    placeholder="Enter Your Email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      style: { color: "#000" },
                      disableUnderline: true,
                    }}
                  />
                </Box>
                {formik.touched.email && formik.errors.email && (
                  <Typography variant="body1" className={classes.error}>
                    {formik.errors.email}
                  </Typography>
                )}

                <Box height={20} />

                <Box className={classes.TextField__box}>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    variant="standard"
                    name="phone"
                    type="number"
                    value={formik.values.phone}
                    placeholder="Enter Your Number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      style: { color: "#000" },
                      disableUnderline: true,
                    }}
                  />
                </Box>
                {formik.touched.phone && formik.errors.phone && (
                  <Typography variant="body1" className={classes.error}>
                    {formik.errors.phone}
                  </Typography>
                )}

                <Box height={20} />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                  }}
                >
                  <Box>
                    <Checkbox
                      style={{ color: "#2979ff" }}
                      {...label}
                      name="check"
                      value={formik.values.check}
                      placeholder="Message...."
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.check && formik.errors.check && (
                      <Typography variant="body1" className={classes.error}>
                        {formik.errors.check}
                      </Typography>
                    )}
                  </Box>
                  <Box>
                    <Typography color={"#999"} fontSize={"13px"}>
                      I agree to matsya fincap collecting & processing my
                      personal data.{" "}
                    </Typography>
                  </Box>
                </Box>

                <Box height={20} />

                <Box textAlign={"center"}>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={!(formik.isValid && formik.dirty)}
                    className={classes.contact___btn}
                  >
                    Submit
                  </Button>
                </Box>
              </form>
            </Box>
          </DialogContent>
        </Dialog>
      <SuccessPop toggleSuccess={toggleSuccess} successPop={successPop} message={message}   />
      </Box>
    </>
  );
};

export default ContactForm;
