import { Box, Link, Typography } from "@mui/material";
import React from "react";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import logo from "../../assets/images/Frame 1.png";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  usePopupState,
  bindHover,
  bindPopper,
} from "material-ui-popup-state/hooks";
import { Popper } from "@mui/material";


const NavBar = () => {
  const popupState1 = usePopupState({
    variant: "popper",
    popupId: "demoPopper",
  });
  const popupState2 = usePopupState({
    variant: "popper",
    popupId: "demoPopper",
  });
  return (
    <>
      <Box
        sx={{
          "@media(max-width:900px)": {
            display: "none",
          },
          "& li":{
            display:"flex",
            alignItems:"center"
          }
        }}
      >
        <div className="container">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: "75px",
            }}
          >
            <Typography
              component={"img"}
              src={logo}
              alt="logo here"
              sx={{
                width: "150px",
                height: "97px",
              }}
            />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                component={"ul"}
                sx={{
                  display: "flex",
                  gap: "4rem",
                  listStyle: "none",
                  marginTop: "1rem",
                }}
              >
                <Typography component={"li"}><Link href="/" variant="body2" sx={{color:'#000', textDecoration:'none', cursor:'pointer'}}>Home</Link></Typography>
                <Typography component={"li"}><Link href="/about" variant="body2" sx={{color:'#000', textDecoration:'none', cursor:'pointer'}}>About</Link></Typography>
                <Typography component={"li"} lineHeight={1.5} variant="body2" {...bindHover(popupState1)} sx={{cursor:"pointer",fontSize:"14px"}}>Policies & Codes <ExpandMoreIcon sx={{fontSize:"1.2rem"}}/></Typography>
                {/* <Typography component={"li"} lineHeight={1.5} variant="body2" sx={{cursor:"pointer",fontSize:"14px"}} {...bindHover(popupState2)}>DLA & LSP <ExpandMoreIcon sx={{fontSize:"1.2rem"}}/></Typography> */}
                <Typography component={"li"}><Link href="/services" variant="body2" sx={{color:'#000', textDecoration:'none', cursor:'pointer'}}>Services</Link></Typography>
                <Typography component={"li"}><Link href="/contact" variant="body2" sx={{color:'#000', textDecoration:'none', cursor:'pointer'}} >Contact</Link></Typography>
                {/* <Typography component={"li"}><Link href="#" variant="body2" sx={{color:'#000', textDecoration:'none', cursor:'pointer'}} onClick={handleModalOpen}>Contact</Link></Typography> */}
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Typography component={"span"}>
                <SupportAgentIcon
                  sx={{ fontSize: "4rem", color: "#008fd5", marginTop: "-5px" }}
                />
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  component={"span"}
                  sx={{ fontSize: "0.7rem", color: "gray", fontWeight: 600 }}
                >
                  CALL TIMING
                </Typography>
                <Typography component={"span"}>011-40197147</Typography>
              </Box>
            </Box>
          </Box>
        </div>
      </Box>
      {/* <Box>
        <Container>
          <Box
            sx={{
              display: "none",
               "@media(max-width:900px)": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              },
            }}
          >
            <Typography
              component={"img"}
              src={logo}
              alt="logo here"
              sx={{
                width: "150px",
                height: "50px",
                "@media(max-width:900px)": {
                  height: "100px",
                },
              }}
            />
            <MenuIcon
              onClick={handleToggle}
              sx={{ fontSize: "2.8rem", marginTop:"-20px" }}
            />
          </Box>
        </Container>
      </Box> */}
      <Popper {...bindPopper(popupState1)}>
        <Box
          sx={{
            background: "#fff",
            boxShadow:"0px 0px 8px 0px rgba(0, 0, 0, 0.15)",
            padding: "1rem",
            borderRadius: "0.3rem",
            width: "220px",
            position: "absolute",
            left: "-4rem",
          }}
        >
           <Typography><Link href="/privacy-policy" variant="body2" sx={{color:'#000', textDecoration:'none', cursor:'pointer',pb:"0.4rem"}} >Policy Privacy</Link></Typography>
           <Typography><Link href="/terms-and-conditions" variant="body2" sx={{color:'#000', textDecoration:'none', cursor:'pointer',pb:"0.4rem"}} >Terms & Conditions</Link></Typography>
           <Typography><Link href="/fair-practice" variant="body2" sx={{color:'#000', textDecoration:'none', cursor:'pointer',pb:"0.4rem"}} >Fair Practice Code</Link></Typography>
           <Typography><Link href="/interest-rate" variant="body2" sx={{color:'#000', textDecoration:'none', cursor:'pointer',pb:"0.4rem"}} >Interest Rate Policy</Link></Typography>
           <Typography><Link href="/grievance-redressal" variant="body2" sx={{color:'#000', textDecoration:'none', cursor:'pointer',pb:"0.4rem"}} >Grievance Redressal policy</Link></Typography>
        </Box>
      </Popper>
      <Popper {...bindPopper(popupState2)} >
        <Box
          sx={{
            background: "#fff",
            boxShadow:"0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
            padding: " 1rem",
            borderRadius: "0.3rem",
            width: "150px",
            position: "absolute",
            left: "-3rem",
          }}
        >
           <Typography><Link href="/credit-lab" variant="body2" sx={{color:'#000', textDecoration:'none', cursor:'pointer'}} >CreditLab</Link></Typography>
          
        </Box>
      </Popper>
    </>
  );
};

export default NavBar;
