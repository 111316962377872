import { Box, Typography } from "@mui/material";
import React from "react";

const InterestRatePolicy = () => {
  return (
    <Box
      className="container"
      py={"1rem"}
      sx={{
        "& p": {
          fontFamily:
            "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
          textAlign: { sm: "start", xs: "justify" },
        },
      }}
    >
      <div className="block-title text-center">
        <Typography
          component={"h2"}
          sx={{ fontSize: { sm: "2.5rem", xs: "1.5rem" } }}
        >
         Interest Rate Policy
        </Typography>
      </div>
      <Typography pb={"1rem"}>
      <b>Preamble:</b>
      <br/>
      Reserve Bank of India through notification No. DNBS. 204 / CGM (ASR)-2009 dated January 2, 2009 and vide its Guidelines on FPC for NBFCsDNBS.CC.PD.No.266/03.10.01/2011-12 dated March 26, 2012 have directed all NBFCs to:
      </Typography>
      <Typography pb={"1rem"}>
      Communicate the annualized rate of interest to the borrower along with the approach for gradation of risk and rationale for charging different rates of interest to different categories of borrowers.
      </Typography>
      <Typography pb={"1rem"}>
      Make available the rates of interest and the approach for gradation of risks on the web-site of the companies.
      </Typography>
      <Typography pb={"1rem"}>
      <b>Rate of interest:</b>
      <br/>
      The Company, at the time of sanction of the loan, intimates in writing the following with the borrower:
      </Typography>
      <Typography pb={"1rem"}>
      1) Loan amount
      <br/>
      2) Rate of interest
      <br/>
      3) Other fees including processing fees charged or to be charged by our partner
      <br/>
      4) loan amount sanctioned
      <br/>
      5) Tenure of the Loan
      <br/>
      6) due dates of the monthly installments.

      </Typography>
      <Typography pb={"1rem"}>
      The rate of interest & any associated fee is arrived at based on the weighted average cost of funds, average customer acquisition cost, administrative and operational costs, risk premium, and profit margin.
      </Typography>
      <Typography pb={"1rem"}>
      The rates of interest are subject to change as the situation warrants and are subject to the discretion of the management and/or changes to extraneous cost factors which has a say in the setting up of the interest rate.
      </Typography>
      <Typography pb={"1rem"} fontWeight={"600"}>
      Approach for Risk Gradation:
      </Typography>
      <Typography pb={"1rem"}>
      Matsya Fincap Private Limited grants credit facilities only to those customers who have both the intention and the ability to discharge their obligations. To execute a smooth underwriting process the Company carries out different processes as per Know Your Customer guidelines and allocates credit grade for each customer.When assessing credit transactions, the Company focuses on critical principles like the history of the Company or Borrower, Financial Leverage, Liquidity and Sources of Cash, Profitability of Operations, and Collateral being provided. The determination of a customer’s credit grading is generally distinguished by the asset type and its use and is mostly based on four general categories, Character, Capacity, Capital, and Collateral.
      </Typography>
   
    </Box>
  );
};

export default InterestRatePolicy;
